<template>
  <v-container fill-height>
    <v-row justify="center">
      <v-card elevation="5" class="mt-16 pa-3" max-width="400">
        <v-overlay absolute :value="loading">
          <v-progress-circular
              :size="70"
              :width="7"
              color="primary"
              indeterminate
          ></v-progress-circular>
        </v-overlay>
        <v-form v-model="isValid" ref="form" @submit.prevent="submit">
          <v-card-title>Login</v-card-title>
          <v-alert
              dense
              outlined
              type="error"
              v-if="showError"
          >{{error}}</v-alert>
          <v-card-text>
            <v-text-field label="Email" v-model="email" :rules="emailRules" required></v-text-field>
            <v-text-field label="Passwort" v-model="password" :rules="passwordRules" type="password"
                          required></v-text-field>
          </v-card-text>
          <v-card-actions>
            <v-btn type="submit" color="primary">
              Anmelden
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-row>
  </v-container>
</template>

<script>

export default {
  name: 'LoginForm',
  props: {
  },
  data: () => ({
    email: null,
    password: null,
    error: "",
    showError: false,
    isValid: true,
    e1: true,
    loading: false,
    passwordRules: [
      (v) => !!v || 'Password is required',
    ],
    emailRules: [
      (v) => !!v || 'E-mail is required',
      (v) => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'E-mail must be valid'
    ],
  }),
  methods: {
    submit() {
      if (this.$refs.form.validate()) {
        let self = this;
        this.loading = true;
        this.$http.post(`${process.env.VUE_APP_ENDPOINT}/admin/login`,
            {
              email: self.email,
              password: self.password,
            },
            {timeout: 20000, withCredentials: true}
        )
            .then(function (response) {
              self.$store.dispatch('updateUser', response.data);
              self.$router.push('/');
            })
            .catch(function (error) {
              if (error.response.status === 500) {
                self.error = "Da ist was schief gelaufen..."
              } else {
                self.error = "Zugangsdaten ungültig - Bitte versuchen Sie es erneut."
              }
              self.showError = true
            })
            .finally(() => this.loading = false)
        ;
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
